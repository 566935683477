<template>
  <div
    :class="
      'message-form flex-wrap flex-center ' + (dialogId ? '' : 'disabled')
    "
  >
    <textarea
      name="message"
      class="messages-textarea"
      :placeholder="$t('messages.chat.type_a_message')"
      :disabled="!dialogId"
      @input="onInput"
      v-model="textareaValue"
      ref="messagesTextarea"
    ></textarea>

    <button
      :class="'af-button send-message ' + (errorText ? 'blocked' : '')"
      @click="sendMessage"
    >
      {{ $t("messages.input_message.send") }}
    </button>
  </div>

  <div v-if="errorText" role="alert" class="error">
    <i class="fas fa-exclamation-circle"></i>
    {{ errorText }}
  </div>
</template>

<script>
import { ref } from "vue";

import { sendMessage as sendMessageApi } from "api/profile/messages.js";

/** Максимальная длина сообщения */
const MAX_MESSAGE_LENGTH = 5000;

export default {
  name: "InputMessage",
  props: {
    /**
     * Id диалога, для которого нужно отправить сообщение
     */
    dialogId: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Id объявления, для которого нужно отправить сообщение
     */
    adId: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: [
    /**
     * Сообщение было отправлено
     */
    "message-sent",
  ],
  setup() {
    return {
      textareaValue: ref(""),
      errorText: ref(""),
    };
  },
  methods: {
    onInput(v) {
      var input = v.target.value;

      var area = this.$refs.messagesTextarea;
      if (area) {
        // TODO: уменьшать высоту если немного текста
        if (input.length < 15) {
          area.style.height = "40px";
        } else {
          area.style.height = area.scrollHeight + "px";
        }
      }

      if (input.length <= MAX_MESSAGE_LENGTH) {
        this.errorText = "";
      } else {
        this.errorText = this.$t("messages.input_message.too_long", {
          x: MAX_MESSAGE_LENGTH,
        });
      }
    },
    async sendMessage() {
      if (!this.dialogId || !this.adId) {
        return;
      }

      if (this.textareaValue.length < 1) {
        return;
      }

      // TODO: block/unblock message textarea

      await sendMessageApi({
        adId: this.adId,
        dialogId: this.dialogId,
        messageText: this.textareaValue,
      });

      this.$emit("message-sent");
      this.textareaValue = "";
      this.errorText = "";
    },
  },
};
</script>

<style scoped>
textarea {
  overflow: auto;
  margin: 0;
  padding: 5px;
  margin-right: 5px;
  height: 30px;
  max-height: 100px;
}

.blocked {
  pointer-events: none;
  opacity: 0.5;
}

.error {
  color: red;
  background: white;
  text-align: start;
  padding: 10px 15px;
  padding-top: 0px;
}
</style>
