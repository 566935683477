<template>
  <div>
    <h1 class="sr-only">{{ $t("messages.messages_page.title") }}</h1>

    <div class="author-panel">
      <div class="white-block">
        <div class="messages-wrap flex-wrap">
          <div class="messages-left animation">
            <ListProfiles
              ref="listProfilesRef"
              @dialog-selected="dialogSelected"
            />
          </div>

          <div class="messages-right">
            <Chat
              :dialogId="selectedDialogId"
              :adId="selectedAdId"
              @focus-chats="
                () => {
                  if (listProfilesRef) {
                    listProfilesRef.focusChats();
                  }
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useIntervalFn } from "@vueuse/core";
import { useMeta } from "vue-meta";

import {
  init as initJQuery,
  destroy as destroyJQuery,
} from "public/adifier/wp-content/themes/adifier/js/profile/messages.js";

import Chat from "../components/messages/Chat.vue";
import ListProfiles from "../components/messages/ListProfiles.vue";

import { getNewMessagesCount as getNewMessagesCountApi } from "api/profile/messages.js";

/** Интервал загрузки количества сообщений */
const MESSAGES_COUNT_FETCH_INTVL = 30000;

/** Интервал мигания заголовка страницы (если есть новые сообщения) */
const PAGE_TITLE_CHANGE_INTVL = 1000;

export default {
  name: "Messages",
  components: {
    Chat,
    ListProfiles,
  },
  setup() {
    const { t } = useI18n();

    const { meta } = useMeta({
      title: t("messages.messages_page.title"),
    });

    var newDialogReloadTimeout = null;

    const listProfilesRef = ref(null);

    var pageTitleIsMessage = false;
    var pageTitleNewMessages = 0;

    const {
      pause: pauseTitleInterval,
      resume: startTitleInterval,
      isActive: isActiveTitleInterval,
    } = useIntervalFn(
      () => {
        if (pageTitleIsMessage) {
          meta.title = t("messages.messages_page.title");
        } else {
          meta.title = t("messages.messages_page.title_new_messages", {
            x: pageTitleNewMessages,
          });
        }
        pageTitleIsMessage = !pageTitleIsMessage;
      },
      PAGE_TITLE_CHANGE_INTVL,
      { immediate: false }
    );

    const newDialogReloadFn = async () => {
      var newMessages = await getNewMessagesCountApi();

      if (newMessages > 0) {
        if (listProfilesRef.value) {
          await listProfilesRef.value.loadData();
        }

        if (!isActiveTitleInterval._value) {
          startTitleInterval();
        }
      } else {
        if (pageTitleNewMessages > 0 && listProfilesRef.value) {
          await listProfilesRef.value.loadData();
        }

        if (isActiveTitleInterval._value) {
          pauseTitleInterval();
        }

        meta.title = t("messages.messages_page.title");
      }

      pageTitleNewMessages = newMessages;

      newDialogReloadTimeout = setTimeout(
        newDialogReloadFn,
        MESSAGES_COUNT_FETCH_INTVL
      );
    };

    onBeforeUnmount(() => {
      if (newDialogReloadTimeout) {
        clearTimeout(newDialogReloadTimeout);
      }

      destroyJQuery();
    });

    const loadData = async () => {
      listProfilesRef.value.loadData();
      newDialogReloadTimeout = setTimeout(
        newDialogReloadFn,
        MESSAGES_COUNT_FETCH_INTVL
      );

      initJQuery();
    };

    return {
      loadData,
      listProfilesRef,
      selectedDialogId: ref(null),
      selectedAdId: ref(null),
    };
  },
  methods: {
    dialogSelected({ dialogId, adId }) {
      this.selectedDialogId = dialogId;
      this.selectedAdId = adId;
    },
  },
};
</script>

<style scoped>
.white-block {
  margin-bottom: 0;
}
</style>

<style>
.author-dashboard main {
  margin: 50px 0 10px 0;
}
</style>
