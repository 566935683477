<template>
  <div class="adverts-filter conversation-filters-wrap">
    <!-- TODO: искать в сообщениях -->
    <form class="conversation-filter" style="height: 40px;">
      <!-- <div class="flex-wrap flex-center">
        <input
          type="text"
          name="keyword"
          placeholder="Filter by advert / user..."
        />
        <i class="aficon-search"></i>
      </div> -->
    </form>
  </div>

  <div class="conversations-window-wrap">
    <div class="conversations-listing-wrap">
      <div class="ajax-conversations">
        <p
          v-if="dialogs.length == 0 && isLoading"
          class="no-conversations text-center"
        >
          {{ $t("messages.list_profiles.no_messages") }}
        </p>

        <ul
          v-else
          class="conversations-list"
          :aria-label="$t('messages.list_profiles.dialogs_list')"
          tabindex="0"
          ref="conversationsList"
        >
          <li :key="d.uuid" v-for="(d, index) in dialogs">
            <Profile
              :date="d.lastMessage ? d.lastMessage.createdAt : null"
              :adName="d.ad ? d.ad.name : null"
              :adPrice="d.ad ? d.ad.price : null"
              :lastMessage="d.lastMessage"
              :isUnread="d.unreadMessages"
              :mainPhoto="d.ad ? d.ad.mainPhoto : null"
              @clicked="profileClicked(d)"
            />
            <div
              v-if="index == dialogs.length - 1"
              ref="refPaginationDiv"
            ></div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div v-if="isLoading">
    <strong>{{ $t("messages.list_profiles.loading") }}</strong>
  </div>

  <!-- DO NOT DELETE: div ajax-conversations-pagination is used in 
       public/messages.js to adjust conversation box height -->
  <div class="ajax-conversations-pagination"></div>

  <div class="adverts-filter conversation-filters-wrap flex-wrap flex-center">
    <!-- TODO: разрешить удалять диалоги -->
    <!-- <ul class="list-inline list-unstyled">
      <li>
        <a href="javascript:void(0);" class="toggle-list">
          Check All
        </a>
      </li>
    </ul>
    <ul class="list-inline list-unstyled">
      <li>
        <a
          href="javascript:void(0);"
          class="delete-conversations"
          data-confirm="Are you sure?"
        >
          Delete Selected
        </a>
      </li>
    </ul> -->
  </div>
</template>

<script>
import { ref } from "vue";
import { useIntersectionObserver } from "@vueuse/core";

import { getDialogs as getDialogsApi } from "api/profile/messages.js";

import Profile from "./Profile.vue";

export default {
  name: "ListProfiles",
  components: {
    Profile,
  },
  emits: [
    /**
     * Был выбран диалог
     * Параметры:
     *  1. object {
     *    dialogId (id диалога),
     *    adId (id объявления)
     *  }
     */
    "dialog-selected",
  ],
  setup() {
    const dialogs = ref([]);
    const cursor = ref("");

    const isLoading = ref(false);

    /**
     * Загрузить список диалогов
     * @param {Boolean} {overwrite} Если true, загрузит список диалогов заново.
     *   Иначе продолжит с текущего места.
     * @returns {any}
     */
    const loadDialogs = async ({ overwrite }) => {
      if (isLoading.value) {
        return;
      }

      if (!cursor.value && !overwrite) {
        return;
      }

      isLoading.value = true;
      var r = await getDialogsApi({
        cursor: overwrite ? "" : cursor.value,
        limit: 10,
      });

      if (!r) {
        isLoading.value = false;
        return;
      }

      if (overwrite) {
        dialogs.value = r.dialogs;
      } else {
        dialogs.value.push(...r.dialogs);
      }
      cursor.value = r.cursor;

      isLoading.value = false;
    };

    const selectedDialog = {
      dialogId: null,
      adId: null,
    };

    /** Загрузить данные, которые нужны этому списку.
     * Вызывается родителем.
     * Может быть вызвана несколько раз
     */
    const loadData = async () => {
      await loadDialogs({ overwrite: true });
    };

    const refPaginationDiv = ref(null);
    const refPaginationDivVisible = ref(false);

    useIntersectionObserver(refPaginationDiv, ([{ isIntersecting }]) => {
      refPaginationDivVisible.value = isIntersecting;
    });

    return {
      dialogs,
      cursor,
      loadData,
      loadDialogs,
      refPaginationDiv,
      refPaginationDivVisible,
      isLoading,
      selectedDialog,
    };
  },
  watch: {
    async refPaginationDivVisible(v) {
      if (!v) {
        return;
      }

      await this.loadDialogs({ overwrite: false });
    },
  },
  methods: {
    profileClicked(dialog) {
      this.selectedDialog.dialogId = dialog.uuid;
      this.selectedDialog.adId = dialog.ad ? dialog.ad.uuid : null;

      this.$emit("dialog-selected", {
        dialogId: this.selectedDialog.dialogId,
        adId: this.selectedDialog.adId,
      });
    },
    /** Сфокусироваться на списке диалогов. Используется родителем */
    focusChats() {
      var list = this.$refs.conversationsList;
      if (list) {
        list.focus();
      }
    },
  },
};
</script>

<style scoped>
.scroll-wrapper {
  overflow: hidden;
}

.scroll-wrapper > .scroll-content {
  overflow: scroll;
}

.conversations-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.conversation-wrap.current,
.conversation-wrap:hover {
  border-left-color: #1c83fc;
}

.no-conversations {
  font-size: 16px;
  margin-top: 16px;
}
</style>
