<template>
  <div class="messages-header flex-wrap flex-center">
    <h5 class="no-margin flex-wrap flex-center">
      <a href="javascript:;" class="toggle-conversations">
        <i class="aficon-comments"></i>
      </a>
      <span class="paste-advert-title">
        <router-link
          v-if="ad && ad.name"
          :to="{ name: 'AdDetails', params: { id: ad.uuid } }"
          :title="$t('messages.chat.open_ad', { name: ad.name })"
          :aria-label="$t('messages.chat.open_ad', { name: ad.name })"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ ad.name }}
        </router-link>
        <span v-else class="small-display">
          <span class="con-arrow-hide"> ← </span>
          {{ $t("messages.chat.click_to_see_conversations_list") }}
        </span>
      </span>
    </h5>
    <div class="conversation-review"></div>
  </div>
  <div class="messages-window-wrap">
    <div v-if="isLoading" class="loading-box-wrap">
      <div class="loading-box">
        {{ $t("messages.list_messages.loading") }}
      </div>
    </div>

    <div
      class="message-listing-wrap"
      ref="messageListingWrap"
      @keydown.left="$emit('focus-chats')"
    >
      <div
        class="message-listing"
        tabindex="0"
        :aria-label="$t('messages.chat.messages_list')"
      >
        <ListMessages
          :messages="messages"
          @scrolled-to-top="listMessagesScrolledTop"
        />
        <div tabindex="0" aria-hidden="true" ref="dialogStart"></div>
      </div>
    </div>
  </div>
  <div class="messages-footer">
    <InputMessage
      :dialogId="dialogId"
      :adId="adId"
      @message-sent="messageSent"
    />
  </div>
</template>

<script>
import { ref, nextTick } from "vue";

import ListMessages from "./ListMessages.vue";
import InputMessage from "./InputMessage.vue";

import {
  getMessages as getMessagesApi,
  hasNewMessages as hasNewMessagesApi,
  setViewedDialog as setViewedDialogApi,
} from "api/profile/messages.js";

var playAlert = null;
try {
  playAlert = require("alert-sound-notify");
} catch (e) {
  console.error("Error loading alert-sound-notify: ", e);
}

/** Время, раз в которое нужно проверять наличие новых сообщений */
const MESSAGE_REFRESH_TIME_MS = 30000;

export default {
  name: "Chat",
  props: {
    /** Id диалога, для которого нужно показать сообщения */
    dialogId: {
      type: String,
      required: false,
      default: null,
    },
    /** Id объявления, для которого нужно показать сообщения */
    adId: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: [
    /**
     * Была нажата клавиша налево, нужно сфокусироваться на чатах
     */
    "focus-chats",
  ],
  components: {
    ListMessages,
    InputMessage,
  },
  setup() {
    return {
      ad: ref(null),
      cursor: ref(null),
      messages: ref(null),
      isLoading: ref(false),
      /** Закончились ли сообщения для этого dialogId */
      hasEnded: ref(false),
      /** Игнорировать listMessagesScrolledTop */
      ignoreScrolledTop: false,
      checkNewMessagesTimeout: null,
    };
  },
  mounted() {
    this.checkNewMessagesTimeout = setTimeout(
      this.checkNewMessages,
      MESSAGE_REFRESH_TIME_MS
    );
  },
  beforeUnmount() {
    if (this.checkNewMessagesTimeout) {
      clearTimeout(this.checkNewMessagesTimeout);
    }
  },
  watch: {
    async dialogId() {
      await this.reloadData();
    },
  },
  methods: {
    async listMessagesScrolledTop() {
      if (this.ignoreScrolledTop) {
        return;
      }

      await this.fetchMessages({ overwrite: false });
    },
    async messageSent() {
      // wait for the server to process the message, it might not appear right after
      await new Promise((resolve) => setTimeout(resolve, 1500));

      await this.reloadData();
    },
    /**
     * Получить список всех сообщений в диалоге
     * @param {Boolean} {overwrite} - нужно ли перезаписать сообщения заново.
     *     Если false, получит сообщения с последним курсором
     */
    async fetchMessages({ overwrite }) {
      if (this.isLoading) {
        return;
      }

      if (this.hasEnded && !overwrite) {
        return;
      }

      if (!this.dialogId) {
        if (overwrite) {
          console.error(
            "Tried overwriting dialog with empty dialogId = ",
            this.dialogId
          );
        }
        return;
      }

      if (overwrite) {
        this.cursor = null;
      }

      this.isLoading = true;
      var r = await getMessagesApi({
        dialogId: this.dialogId,
        limit: 15,
        cursor: this.cursor,
      });

      this.ad = r.ad;
      this.cursor = r.cursor;
      this.hasEnded = !r.cursor;
      if (overwrite) {
        this.messages = r.messages.reverse();
      } else {
        this.messages = [...r.messages.reverse(), ...this.messages];
      }

      try {
        if (overwrite) {
          await setViewedDialogApi({
            dialogId: this.dialogId,
          });
        }
      } catch (e) {
        console.error("Unable to set viewed: ", e);
      }

      this.isLoading = false;
    },

    /**
     * Удалить все данные и заново загрузить диалог
     */
    async reloadData() {
      this.ignoreScrolledTop = true;

      this.fetchMessages({ overwrite: true });
      await nextTick();
      await new Promise((resolve) => setTimeout(resolve, 500));

      var scrollEl = this.$refs.messageListingWrap;
      if (scrollEl) {
        scrollEl.scrollTo(0, scrollEl.scrollHeight);
      }
      if (this.$refs.dialogStart) {
        this.$refs.dialogStart.focus();
      }

      this.ignoreScrolledTop = false;
    },
    async checkNewMessages() {
      if (this.isLoading || this.ignoreScrolledTop) {
        this.checkNewMessagesTimeout = setTimeout(
          this.checkNewMessages,
          MESSAGE_REFRESH_TIME_MS
        );
        return;
      }

      if (!this.dialogId) {
        this.checkNewMessagesTimeout = setTimeout(
          this.checkNewMessages,
          MESSAGE_REFRESH_TIME_MS
        );
        return;
      }

      if (!this.messages || !this.messages[this.messages.length - 1]) {
        this.checkNewMessagesTimeout = setTimeout(
          this.checkNewMessages,
          MESSAGE_REFRESH_TIME_MS
        );
        return;
      }

      var lastMsg = this.messages[this.messages.length - 1];
      var hasNew = await hasNewMessagesApi({
        dialogId: this.dialogId,
        lastMessageId: lastMsg.uuid,
      });

      if (hasNew) {
        if (playAlert) {
          playAlert("submarine");
        }
        await this.reloadData();
      }

      this.checkNewMessagesTimeout = setTimeout(
        this.checkNewMessages,
        MESSAGE_REFRESH_TIME_MS
      );
    },
  },
};
</script>

<style scoped>
.loading-box-wrap {
  position: relative;
}

.loading-box {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #f8f8f8;
  padding: 10px;
  z-index: 99;
}

.aficon-comments {
  color: #1c83fc;
}

.message-listing-wrap {
  overflow: auto;
}

.messages-textarea {
  margin: 0;
}
</style>

<style>
.scroll-wrapper.message-listing-wrap {
  overflow: hidden;
}
</style>
