<template>
  <div v-if="day" class="message-date-header-wrap">
    <div class="message-date-header">{{ timeText(day) }}</div>
  </div>

  <div :key="group.id" v-for="group in userGroup">
    <div
      :class="
        'message-wrap flex-wrap ' +
          (isMine(group.user) ? 'this-user' : 'other-user')
      "
    >
      <router-link
        v-if="!isMine(group.user)"
        :to="{ name: 'UserDetails', params: { id: group.user.userId } }"
        class="message-avatar"
      >
        <img
          :alt="
            $t('messages.the_messages.user_profile', { x: group.user.username })
          "
          :src="group.user.thumbUrl"
          class="avatar avatar-50 photo"
          width="50"
          height="50"
        />
      </router-link>

      <div class="flex-right">
        <div :key="msg.id" v-for="msg in group.messages" class="flex-wrap">
          <div class="message">{{ msg.text }}</div>
          <div class="message-time">{{ timeText(msg.createdAt) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheMessages",
  props: {
    /** День, в который была отправлена группа сообщений */
    day: [String, Date],
    /** Группа сообщений пользователя */
    userGroup: Array,
  },
  methods: {
    timeText(date) {
      if (typeof date == "string") {
        return date.trim();
      }

      return (
        ("00" + date.getHours()).slice(-2) +
        ":" +
        ("00" + date.getMinutes()).slice(-2)
      );
    },
    isMine(user) {
      if (!user) {
        return false;
      }

      if (!user.userId) {
        return false;
      }

      return user.userId == this.$store.state.user.userId;
    },
  },
};
</script>

<style scoped>
.message-date-header-wrap {
  margin-top: 10px;
}

.message-avatar {
  position: relative;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}

.message-avatar img {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  width: 50px;
  height: auto;
}
</style>
