<template>
  <a
    :class="
      'conversation-wrap animation' +
        (isActive ? ' current' : '') +
        (isUnread ? ' unread' : '')
    "
    @click="$emit('clicked')"
    @keydown.enter="$emit('clicked')"
    @keydown.space="$emit('clicked')"
    tabindex="0"
  >
    <div class="start-messages">
      <div class="flex-wrap flex-start">
        <div class="flex-left">
          <img
            :src="mainPhoto && mainPhoto.thumb ? mainPhoto.thumb : EmptyImg"
            class="avatar avatar-96 photo avatar-default"
            width="96"
            height="96"
            aria-hidden="true"
          />
          <i
            class="aficon-spin aficon-circle-notch con-loading con-loading-25 hidden"
          ></i>
        </div>
        <div class="flex-right">
          <div
            v-if="Boolean(adName)"
            class="username"
            :aria-label="$t('messages.profile.ad_name', { x: renderedAdName })"
          >
            {{ renderedAdName }}
          </div>

          <p
            v-if="adPrice"
            class="ad-price"
            :aria-label="
              $t('messages.profile.ad_price', { x: Number(adPrice) })
            "
          >
            {{ Number(adPrice).toLocaleString() + " " + $t("$") }}
          </p>

          <div
            class="conversation-last-message"
            :aria-label="
              $t('messages.profile.last_message', {
                x: renderedLastMessageText,
              })
            "
          >
            {{ renderedLastMessageText }}
          </div>

          <div
            v-if="Boolean(date)"
            class="profile-small-title conversation-time"
            :aria-label="
              $t('messages.profile.last_message_date', { x: renderedDate })
            "
          >
            {{ renderedDate }}
          </div>
        </div>
      </div>
    </div>
    <!-- TODO: разрешить удалять диалоги -->
    <!-- <div class="styled-checkbox">
      <input type="checkbox" id="25" name="con_ids[]" value="25" />
      <label for="25"></label>
    </div> -->
  </a>
</template>

<script>
/** Максимальная длина названия объявления */
const ADNAME_LENGTH = 70;

/** Максимальная длина последнего сообщения */
const MESSAGE_TEXT_LENGTH = 150;

import EmptyImg from "assets/img/empty-image.svg";

export default {
  name: "Profile",
  props: {
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    date: {
      type: Date,
      required: false,
      default() {
        return new Date();
      },
    },
    adName: {
      type: String,
      required: false,
      default:
        "генеральная уборка квартир, домов. 9 сентября 2021 142412, Московская обл, г Ногинск, ул Климова, д 50 ₽ 25 fdsoiu8ifygwehbjj v eij",
    },
    adPrice: {
      type: Number,
      required: false,
      default: null,
    },
    lastMessage: {
      type: Object,
      required: false,
      default() {
        return {
          text: "",
          isMe: false,
        };
      },
    },
    mainPhoto: {
      type: Object,
      required: false,
      default() {
        return {
          thumb: null,
          small: null,
        };
      },
    },
    isUnread: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    /** Профиль был кликнут */
    "clicked",
  ],
  setup() {
    return {
      EmptyImg,
    };
  },
  computed: {
    renderedDate() {
      return this.date.toLocaleDateString({
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    },
    renderedAdName() {
      if (this.adName.length > ADNAME_LENGTH) {
        return this.adName.slice(0, ADNAME_LENGTH) + "...";
      }
      return this.adName;
    },
    renderedLastMessageText() {
      if (!this.lastMessage || !this.lastMessage.text) {
        return "";
      }

      var msgText;

      if (this.lastMessage.text.length > MESSAGE_TEXT_LENGTH) {
        msgText = this.lastMessage.text.slice(0, MESSAGE_TEXT_LENGTH) + "...";
      } else {
        msgText = this.lastMessage.text;
      }

      if (this.lastMessage.isMe) {
        return this.$t("messages.profile.is_me") + " " + msgText;
      }

      return msgText;
    },
  },
};
</script>

<style scoped>
.conversation-wrap {
  width: 100%;
}

.start-messages {
  padding: 20px 14px 20px 14px;
}

.unread {
  background: #e8e8e8;
}

.profile-small-title {
  margin-top: 3px;
}

.username {
  margin: 0;
  text-align: start;
  margin-bottom: 6px;
  color: #333333;
  font-size: 14px;
}

.username:hover {
  color: #333333;
}

.ad-name {
  margin: 0px;
  width: 70%;
  text-align: start;
  font-size: 12px;
  color: #333333;
}

.ad-name:hover {
  color: #333333;
}

.ad-price {
  text-align: start;
}

.conversation-last-message {
  text-align: start;
}

.conversation-wrap img {
  border-radius: 0;
}
</style>
