<template>
  <div
    v-if="messages == null"
    class="text-center author-no-listing messages-no"
  >
    <i class="aficon-reply"></i>
    <h5>{{ $t("messages.chat.select_conversation") }}</h5>
  </div>

  <div
    v-else-if="messages.length == 0"
    class="text-center author-no-listing messages-no"
  >
    {{ $t("messages.list_messages.no_messages") }}
  </div>

  <div v-else>
    <div :key="dayGroup.id" v-for="(dayGroup, index) in groupedMessages">
      <div v-if="index == 0" ref="scrollTrigger"></div>
      <TheMessages :day="dayGroup.day" :userGroup="dayGroup.userGroup" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useIntersectionObserver } from "@vueuse/core";

import TheMessages from "./TheMessages.vue";

export default {
  name: "ListMessages",
  props: {
    /** Список сообщений, который нужно отобразить
     * Должен включать поля:
     *  - createdAt {Date}
     *  - isMe {Boolean}
     *  - text {String}
     *  - user {Object}
     *   - thumbUrl
     *   - userId
     *   - username
     *  - viewed {Boolean}
     */
    messages: {
      type: Array,
      required: false,
      default: null,
    },
  },
  emits: [
    /**
     * Пользователь прокрутил сообщения до верха
     */
    "scrolled-to-top",
  ],
  components: {
    TheMessages,
  },
  setup() {
    const scrollTrigger = ref(null);
    const scrollTriggerVisible = ref(false);

    useIntersectionObserver(scrollTrigger, ([{ isIntersecting }]) => {
      scrollTriggerVisible.value = isIntersecting;
    });

    return {
      groupedMessages: ref([]),
      scrollTrigger,
      scrollTriggerVisible,
    };
  },
  mounted() {
    this.groupMessages();
  },
  watch: {
    messages(v) {
      if (v != null && v.length == 0) {
        return;
      }
      this.groupMessages();
    },
    scrollTriggerVisible(v) {
      if (!v) {
        return;
      }
      this.$emit("scrolled-to-top");
    },
  },
  methods: {
    groupMessages() {
      if (!this.messages) {
        return;
      }

      var dayGroup = [];
      var dayId = 0;

      var prevDay = "";
      var prevUserGroup = [];
      var userGroupId = 0;

      var prevMsgs = [];
      var messageId = 0;
      var prevUser = null;

      for (var m of this.messages) {
        var day =
          m.createdAt.getDate() +
          "." +
          (m.createdAt.getMonth() + 1) +
          "." +
          m.createdAt.getFullYear();

        if (day != prevDay) {
          if (prevDay != "") {
            prevUserGroup.push({
              id: userGroupId,
              messages: prevMsgs,
              user: prevUser,
            });
            userGroupId += 1;

            dayGroup.push({
              id: dayId,
              day: prevDay,
              userGroup: prevUserGroup,
            });
            dayId += 1;
          }

          prevMsgs = [
            {
              id: messageId,
              createdAt: m.createdAt,
              text: m.text,
              viewed: m.viewed,
            },
          ];
          messageId += 1;
          prevUser = m.user;
          prevDay = day;
          prevUserGroup = [];

          continue;
        }

        // todo: check for userId?

        if (m.user.userId != prevUser.userId) {
          prevUserGroup.push({
            id: userGroupId,
            messages: prevMsgs,
            user: prevUser,
          });
          userGroupId += 1;

          prevMsgs = [
            {
              id: messageId,
              createdAt: m.createdAt,
              text: m.text,
              viewed: m.viewed,
            },
          ];
          messageId += 1;
          prevUser = m.user;

          continue;
        }

        prevMsgs.push({
          id: messageId,
          createdAt: m.createdAt,
          text: m.text,
          viewed: m.viewed,
        });
        messageId += 1;
      }

      if (prevDay != "") {
        prevUserGroup.push({
          id: userGroupId,
          messages: prevMsgs,
          user: prevUser,
        });
        userGroupId += 1;

        dayGroup.push({
          id: dayId,
          day: prevDay,
          userGroup: prevUserGroup,
        });
        dayId += 1;
      }

      this.groupedMessages = dayGroup;
    },
  },
};
</script>

<style scoped>
i {
  color: #1c83fc !important;
}
</style>
